<template>
<div id="forgot">

          <h1 class="text-xl md:text-2xl font-bold leading-tight mt-12">
          Forgotten password request
          </h1>

  <form class="mt-6"  v-on:submit.prevent="sendEmail" v-if="!resetComplete">
            <div>
              <label class="block text-gray-700">Username</label>
              <input type="text"
                placeholder="Enter your username"
                v-model="form.username"
                class="
                  rounded-lg
                  mt-2
                  focus:border-blue-500
                  focus:bg-white
                  focus:outline-none
                "
                autofocus
                autocomplete
                required
              />

  
            </div>



 <div class="mt-4">
            <label class="block text-gray-700">Contact Email</label>
            <input type="text"  class="
                  rounded-lg
                  mt-2
                  focus:border-blue-500
                  focus:bg-white
                  focus:outline-none
                " placeholder="Enter your contact email" v-model="form.email" required />
        </div>
    <div class="mt-4">
               <label class="block text-gray-700">School Name</label>
            <input type="text"  class="
                  rounded-lg
                  mt-2
                  focus:border-blue-500
                  focus:bg-white
                  focus:outline-none
                " placeholder="Enter your school name" v-model="form.school" required />
        </div>

     <div class="mt-4">
               <label class="block text-gray-700">School Postcode</label>
            <input type="text"  class="
                  rounded-lg
                  mt-2
                  focus:border-blue-500
                  focus:bg-white
                  focus:outline-none
                " placeholder="Enter your school postcode" v-model="form.postcode" required />
        </div>

            <button
              type="submit"
              id="login-button"
              class="
                w-full
                block
                bg-action-blue
                hover:opacity-75
                focus:bg-action-blue
                text-white
                font-semibold
                rounded-lg
                px-4
                py-3
                mt-6
              "
            >
              Send Request
            </button>
          </form>

                <div style="width:100%; text-align:center;" v-if="loading">
        <i class="spinner fa fa-spinner fa-spin"></i>
    </div>
   <div class="alert bg-red-300 rounded p-4 text-red-700" v-if="errorVisible">
        <p v-html="error"></p>
    </div>
    <div class="bg-green-400 rounded p-4 mt-8 font-semibold text-gray-800 text-base" v-if="resetComplete">
        <p>Your password request has been sent to our support team, who will contact you shortly.</p>
    </div>

        <div class="text-center mt-12">
              <a
                 @click="$emit('back')"
                class="
                  text-sm
                  font-semibold
                  text-gray-700
                  hover:text-blue-700
                  focus:text-blue-700
                  cursor-pointer
                "
                >Back to login</a
              >
            </div>
  
</div>
</template>

<script>
import { mapGetters } from "vuex";
import { BaseService } from "@/services/school.service";

export default {
  name: "login",
  data() {
    return {
      form: {
        email: null,
        school: null,
        postcode: null,
        username: null
      },
      emailValid: false,
      loading: false,
      errorVisible: false,
      resetComplete: false,
      error: "",
      networkMsg:
        "Could not connect to the server. <br/> Please contact our support team for further assistance.",
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  computed: {
    ...mapGetters({ currentUser: "currentUser" }),
    canlogin() {
      return this.username.trim().length > 2 && this.password.trim().length > 3;
    },
    isEmailValid() {
      return this.reg.test(this.form.email)
    },
    submitIsValid() {
      return this.form.school != null && this.isEmailValid;
    },
    showEmailError() {
      return this.form.email != null && !this.isEmailValid
    }
  },
  methods: {
    sendEmail() {
      this.loading = true
      let payload = { 
           "name": this.form.username,
           "email": this.form.email,
           "postcode": this.form.postcode,
           "school": this.form.school
      }

      BaseService.create("authenticate/resetpassword", payload)
          .then(response => this.completion())
          .catch(err => {
            this.failure(err);
            console.log("Reset error " + err);
          });
    },
    completion() {
       this.loading = false
       this.resetComplete = true
    },
    failure(err) {
      this.loading = false
      this.errorVisible = true
      this.error = err === 'network_error' ? this.networkMsg : "Request Failed!";
    },
  },
  components: {
  },
};
</script>

<style scoped>

</style>
